import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import withDashboardLayout from 'layouts/Dashboard/Dashboard';
import { LinearProgress } from '@mui/material';
import { createRouteUrl } from './routeHelper';

// Dashboard
const DashboardDefault = lazy(() => import('../myPages/Dashboard/DashboardDefault'));

// live
const Live = lazy(() =>
    import('../myPages/live/Live'),
);

const AddEditLive = lazy(() =>
    import('../myPages/live/AddEditLive'),
);
const LiveConductorList = lazy(() =>
    import('../myPages/live/conductor/live-conductor-list'),
);
const LiveConductorDetail = lazy(() =>
    import('../myPages/live/conductor/detail/live-conductor-content-list'),
);

const LoopList = lazy(() =>
    import('../myPages/LoopContent/loop-list'),
);
const LoopDetail = lazy(() =>
    import('../myPages/LoopContent/detail/loop-conductor-content-list'),
);

// Content
const ContentSeenLogReport = lazy(() =>
    import('../myPages/manage-content/content-library/Chart/ChartCustom'),
);

const ContentList = lazy(() =>
    import('../myPages/manage-content/content-library/content-list.component'),
);
const AddEditNewContentLayout = lazy(() =>
    import('../myPages/manage-content/addNewContent/AddEditNewContentLayout'),
);
const AddNewContent = lazy(() =>
    import('../myPages/manage-content/addNewContent/addContent.component'),
);
const AddEditNewSeason = lazy(() =>
    import('../myPages/manage-content/content-library/Season-Episode/SeasonPage'),
);

//Video
const VideoList = lazy(() =>
    import('../myPages/manage-content/video-library/videosList.component'),
);

// Comments
const CommentsList = lazy(() => import('../myPages/manage-content/comments/CommentsList.page'));
const ReportedCommentsList = lazy(() =>
    import('../myPages/manage-content/comments/ReportedCommentsList.page'),
);

//Gallery
const GalleryList = lazy(() => import('../myPages/Gallery/image&teasers/Gallery'));
const AvatarsList = lazy(() => import('../myPages/Gallery/Avatars/AvatarsList.page'));

// Cast&Crew
const CastAndCrewList = lazy(() =>
    import('../myPages/manage-content/cast&crew-library/CastAndCrewList'),
);
const AddNewCastCrew = lazy(() =>
    import('../myPages/manage-content/cast&crew-library/AddNewCastCrewPage'),
);
const CastAndCrewRolesList = lazy(() =>
    import('../myPages/manage-content/cast&crewRole/CastCrewRoles'),
);

//Monetization
const PaymentGateWay = lazy(() => import('../myPages/monetization/paymentGateWay/paymentGateway'));
const SubscriptionList = lazy(() => import('../myPages/monetization/Subscriptions/Subscriptions'));
const AddEditNewPlan = lazy(() => import('../myPages/monetization/Subscriptions/AddEditNewPlan'));
const AdsList = lazy(() => import('../myPages/monetization/ADS/AdsListPage'));
const AddEditNewAds = lazy(() => import('../myPages/monetization/ADS/AddEditNewAds.jsx'));
const SubscribersList = lazy(() => import('../myPages/monetization/Subscribers/SubscribersList'));
const CouponsList = lazy(() => import('../myPages/monetization/CVG/coupon/CouponsList'));
const AddEditCoupon = lazy(() => import('../myPages/monetization/CVG/coupon/AddEditCoupon'));
const VouchersList = lazy(() => import('../myPages/monetization/CVG/voucher/VouchersList'));
const addEditNewVoucher = lazy(() => import('../myPages/monetization/CVG/voucher/AddEditNewVoucher'));
const giftsList = lazy(() => import('../myPages/monetization/CVG/gift/GiftsList'));
const addEditNewGift = lazy(() => import('../myPages/monetization/CVG/gift/AddEditNewGift'));

//Pages (PageBuilder)
const PageBuilderDetail = lazy(() => import('../myPages/Page-builders/MainPage/MainPageBuilder'));
const PageBuilderList = lazy(() =>
    import('../myPages/Page-builders/MainPage/Languages/LanguagesList'),
);

//Meta-Data
const TagsPage = lazy(() =>
    import('../myPages/manage-content/meta-data/Tags/TagsList'),
);
const VideoCategoryGenre = lazy(() =>
    import('../myPages/manage-content/meta-data/VideoCategoryGenre/VideoCategoryGenre'),
);
const AddEditNewVideoCategory = lazy(() =>
    import('../myPages/manage-content/meta-data/VideoCategoryGenre/AddEditNewVideoCategory'),
);
const CategoryOfContents = lazy(() =>
    import(
        '../myPages/manage-content/meta-data/VideoCategoryGenre/Category Contents/ContentsOfCategory.page'
    ),
);

// Users
const UsersList = lazy(() => import('myPages/Users/UsersList.page'));

//Admins
const AdminsList = lazy(() => import('myPages/Admins/Admins Management/AdminsList.page'));
const PermissionsList = lazy(() =>
    import('myPages/Admins/permissions/permission-groups-management/PermissionList.page'),
);
const StaticPermissionsList = lazy(() =>
    import('myPages/Admins/permissions/static-permissions/PermissionsPage'),
);
const AddEditAdmin = lazy(() => import('myPages/Admins/Admins Management/AddEditAdmin.page'));

//Analaytics
const UsersAnalaytics = lazy(() => import('myPages/analaytics/users/UsersAnalaytic.page'));

// Reports
const ContentReports = lazy(() => import('myPages/reports/ContentReport'));

// Notifications
const NotificationsList = lazy(() =>
    import('myPages/Notifications/List-of-Notifications/NotificationsList'),
);

const AddEditNotification = lazy(() =>
    import('myPages/Notifications/add-edit-notification/AddEditNotification'),
);

// Settings
const LanguagesListPage = lazy(() => import('myPages/site-settings/Languages/LanguagesList.page'));
const AgeCategories = lazy(() => import('myPages/site-settings/AgeCategories/AgeCategories.page'));
const SignInOptions = lazy(() => import('myPages/site-settings/SignInOptions/SignInOptions.page'));
const CompaniesListPage = lazy(() => import('myPages/site-settings/Companies/CompaniesList.page'));
const SiteSetting = lazy(() => import('myPages/site-settings/site-setting/SiteSetting'));
const UISetting = lazy(() => import('myPages/site-settings/ui-setting/UISiteSetting'));


const ShortList = lazy(() => import('myPages/short/shortContent/ShortList.page'));
const AddShortPage = lazy(() => import('myPages/short/shortContent/addNewShort/AddShort.page'));
const ShortVideoList = lazy(() => import('myPages/short/shortVideo/ShortVideosList.page'));
const ShortComments = lazy(() => import('myPages/short/shortComment/ShortCommentsList.page'));

const ReelList = lazy(() => import('myPages/reel/reelContent/ReelList.page'));
const AddReelPage = lazy(() => import('myPages/reel/reelContent/addNewReel/AddReel.page'));
const ReelVideoList = lazy(() => import('myPages/reel/reelVideo/ReelVideosList.page'));
const ReelComments = lazy(() => import('myPages/reel/reelComment/ReelCommentsList.page'));

const PhotoList = lazy(() => import('myPages/photo/photoContent/PhotoList.page'));
const AddPhotoPage = lazy(() => import('myPages/photo/photoContent/addNewPhoto/AddPhoto.page'));
const PhotoComments = lazy(() => import('myPages/photo/photoComment/PhotoCommentsList.page'));

const PodcastList = lazy(() => import('myPages/podcast/podcastContent/PodcastList.page'));
const AddPodcastPage = lazy(() => import('myPages/podcast/podcastContent/addNewPodcast/AddPodcast.page'));
const PodcastFileList = lazy(() => import('myPages/podcast/podcastFile/PodcastFilesList.page'));
const PodcastComments = lazy(() => import('myPages/podcast/podcastComment/PodcastCommentsList.page'));

const FullContentReportPage = lazy(() => import('myPages/reports/ContentReport'));
const SingleContentReportPage = lazy(() => import('myPages/reports/SingleContentReport'));

//Errors
const Error404 = lazy(() => import('../views/Error404'));

const MainRoutes = () => {
    return (
        <Suspense fallback={<LinearProgress />}>
            <Switch>
                {/*Dashboard */}
                <Route exact path={createRouteUrl('home')} component={DashboardDefault} />

                {/*live */}
                <Route exact path={"/live"} component={Live} />
                <Route exact path={createRouteUrl('addEditLive')} component={AddEditLive} />
                <Route exact path={createRouteUrl('liveConductorList')} component={LiveConductorList} />
                <Route exact path={createRouteUrl('liveConductorDetail')} component={LiveConductorDetail} />

                <Route exact path={createRouteUrl('loopList')} component={LoopList} />
                <Route exact path={createRouteUrl('loopDetail')} component={LoopDetail} />

                {/*Content-library */}
                <Route exact path={createRouteUrl('contentSeenLogReport')} component={ContentSeenLogReport} />

                <Route exact path={createRouteUrl('contentList')} component={ContentList} />
                <Route
                    exact
                    path={createRouteUrl('addEditNewContent')}
                    component={AddEditNewContentLayout}
                />
                <Route exact path={createRouteUrl('addEditSeason')} component={AddEditNewSeason} />

                {/*Video-library */}
                <Route exact path={createRouteUrl('videoList')} component={VideoList} />

                {/*Short */}
                <Route exact path={createRouteUrl('shortList')} component={ShortList} />
                <Route exact path={createRouteUrl('shortDetail')} component={AddShortPage} />
                <Route exact path={createRouteUrl('shortVideos')} component={ShortVideoList} />
                <Route exact path={createRouteUrl('shortComments')} component={ShortComments} />

                {/*Reel */}
                <Route exact path={createRouteUrl('reelList')} component={ReelList} />
                <Route exact path={createRouteUrl('reelDetail')} component={AddReelPage} />
                <Route exact path={createRouteUrl('reelVideos')} component={ReelVideoList} />
                <Route exact path={createRouteUrl('reelComments')} component={ReelComments} />

                {/*Photo */}
                <Route exact path={createRouteUrl('photoList')} component={PhotoList} />
                <Route exact path={createRouteUrl('photoDetail')} component={AddPhotoPage} />
                <Route exact path={createRouteUrl('photoComments')} component={PhotoComments} />

                {/*Podcast */}
                <Route exact path={createRouteUrl('podcastList')} component={PodcastList} />
                <Route exact path={createRouteUrl('podcastDetail')} component={AddPodcastPage} />
                <Route exact path={createRouteUrl('podcastFileList')} component={PodcastFileList} />
                <Route exact path={createRouteUrl('podcastComments')} component={PodcastComments} />

                {/* Comments */}
                <Route exact path={createRouteUrl('commentsList')} component={CommentsList} />
                <Route
                    exact
                    path={createRouteUrl('reportedCommentsList')}
                    component={ReportedCommentsList}
                />

                {/*Gallery */}
                <Route exact path={createRouteUrl('galleryList')} component={GalleryList} />
                <Route exact path={createRouteUrl('avatarsList')} component={AvatarsList} />

                {/*Cast-And-Crew-Library */}
                <Route exact path={createRouteUrl('castAndCrewList')} component={CastAndCrewList} />
                <Route exact path={createRouteUrl('addEditNewCast')} component={AddNewCastCrew} />
                <Route
                    exact
                    path={createRouteUrl('castAndCrewRolesList')}
                    component={CastAndCrewRolesList}
                />

                {/*Payment Gateways */}
                <Route exact path={createRouteUrl('paymentGateway')} component={PaymentGateWay} />

                {/* PageBuilder */}
                <Route exact path={createRouteUrl('pageBuilderLanguage')} component={PageBuilderList} />
                <Route exact path={createRouteUrl('pageBuilderDetail')} component={PageBuilderDetail} />

                {/*Monetization */}
                <Route
                    exact
                    path={createRouteUrl('subscriptionList')}
                    component={SubscriptionList}
                />
                <Route exact path={createRouteUrl('addEditNewPlan')} component={AddEditNewPlan} />
                <Route exact path={createRouteUrl('adsList')} component={AdsList} />
                <Route exact path={createRouteUrl('addEditNewAds')} component={AddEditNewAds} />
                <Route exact path={createRouteUrl('subscribersList')} component={SubscribersList} />
                <Route exact path={createRouteUrl('couponsList')} component={CouponsList} />
                <Route exact path={createRouteUrl('addEditCoupon')} component={AddEditCoupon} />
                <Route exact path={createRouteUrl('vouchersList')} component={VouchersList} />
                <Route exact path={createRouteUrl('addEditNewVoucher')} component={addEditNewVoucher} />
                <Route exact path={createRouteUrl('giftsList')} component={giftsList} />
                <Route exact path={createRouteUrl('addEditNewGift')} component={addEditNewGift} />

                {/* Users */}
                <Route exact path={createRouteUrl('usersList')} component={UsersList} />

                {/* Admins */}
                <Route path={createRouteUrl('adminsList')} component={AdminsList} />
                <Route
                    exact
                    path={createRouteUrl('permissionGroupsList')}
                    component={PermissionsList}
                />
                <Route
                    exact
                    path={createRouteUrl('staticPermissionsList')}
                    component={StaticPermissionsList}
                />

                {/* MetaData */}
                <Route
                    exact
                    path={createRouteUrl('videoCategory')}
                    component={VideoCategoryGenre}
                />
                <Route exact path={createRouteUrl('videoGenre')} component={VideoCategoryGenre} />
                <Route exact path={createRouteUrl('tags')} component={TagsPage} />
                <Route
                    exact
                    path={createRouteUrl('addEditNewVideoCategory')}
                    component={AddEditNewVideoCategory}
                />
                <Route
                    exact
                    path={createRouteUrl('categoryOfContents')}
                    component={CategoryOfContents}
                />

                {/* Analaytics */}
                <Route exact path={createRouteUrl('usersAnalaytics')} component={UsersAnalaytics} />

                {/* Reports */}
                <Route exact path={createRouteUrl('contentReports')} component={FullContentReportPage} />
                <Route exact path={createRouteUrl('contentReport')} component={SingleContentReportPage} />

                {/* Notifications */}
                <Route
                    exact
                    path={createRouteUrl('notificationsList')}
                    component={NotificationsList}
                />

                <Route
                    exact
                    path={createRouteUrl('addEditNotification')}
                    component={AddEditNotification}
                />

                {/* Settings */}
                <Route exact path={createRouteUrl('languages')} component={LanguagesListPage} />
                <Route exact path={createRouteUrl('ageCategories')} component={AgeCategories} />
                <Route exact path={createRouteUrl('oAuth')} component={SignInOptions} />
                <Route exact path={createRouteUrl('companies')} component={CompaniesListPage} />
                <Route exact path={createRouteUrl('siteSetting')} component={SiteSetting} />
                <Route exact path={createRouteUrl('UISiteSetting')} component={UISetting} />
                <Route path="*" component={Error404} />
            </Switch>
        </Suspense>
    );
};

export default withDashboardLayout(MainRoutes);
