const urls = {
    // Dashboard
    home: { url: '/' },

    // Live
    live: { url: '/live' },
    addEditLive: { url: '/live/addEditLive', params: [':id'] },
    liveConductorList: { url: '/live/conductor', params: [':liveId', ":liveTitle"] },
    liveConductorDetail: { url: '/live/conductorDetail', params: [':conductorId'] },

    loopList: { url: '/loop' },
    loopDetail: { url: '/loop/detail', params: [":loopId"] },

    // Content
    contentList: { url: '/management/content/contentLibrary' },
    contentSeenLogReport: { url: '/management/content/report', params: [':id'] },
    addEditNewContent: { url: '/management/content/addEditNewContent', params: [':id'] },
    addEditSeason: { url: '/management/content/season', params: [':id', ':title'] },

    //Video
    videoList: { url: '/management/videos/videoList' },
    addNewVideo: { url: '/management/videos/addNewVideo' },

    //Gallery
    galleryList: { url: '/gallery/image&teasers' },
    avatarsList: { url: '/gallery/avatars' },

    //castAndCrew
    castAndCrewList: { url: '/management/castCrewList' },
    castAndCrewRolesList: { url: '/management/castCrew/roles' },
    addEditNewCast: { url: '/management/castCrew/addEditNewCast', params: [':id'] },

    //Meta-Data
    videoCategory: { url: '/management/videoCategory' },
    videoGenre: { url: '/management/videoGenre' },
    tags: { url: '/management/tags' },
    addEditNewVideoCategory: { url: '/management/videoCategory', params: [':id'] },
    categoryOfContents: {
        url: '/management/videoCategory/contentsOf',
        params: [':name', ':id'],
    },

    //Comments
    commentsList: { url: '/management/commentsList' },
    reportedCommentsList: { url: '/management/reportedCommentsList' },

    //Pages (PageBuilder)
    pageBuilderLanguage: {
        url: '/pages/language',
        params: [':section', ':refId'],
    },
    pageBuilderDetail: {
        url: '/pages/builder',
        params: [':section', ':refId', ':languageId', ':isKid', ':device'],
    },

    //Monetization
    paymentGateway: { url: '/monetization/paymentGateway' },
    subscriptionList: { url: '/monetization/subscriptionList' },
    addEditNewPlan: { url: '/monetization/subscription', params: [':id'] },
    adsList: { url: '/monetization/adsList' },
    addEditNewAds: { url: '/monetization/addEditAds', params: [':id'] },
    subscribersList: { url: '/monetization/subscribersList' },
    couponsList: { url: '/monetization/couponList' },
    addEditCoupon: { url: '/monetization/coupon', params: [':id'] },
    vouchersList: { url: '/monetization/vouchersList' },
    addEditNewVoucher: { url: '/monetization/voucher', params: [':id'] },
    giftsList: { url: '/monetization/giftsList' },
    addEditNewGift: { url: '/monetization/gift', params: [':id'] },

    //Users
    usersList: { url: '/users/list' },

    //Analaytics
    usersAnalaytics: { url: '/analaytics/users' },

    //Admins Management
    adminsList: { url: '/admin/list' },
    addEditAdmin: { url: '/admin/list/addEdit', params: [':id'] },
    permissionGroupsList: { url: '/admin/permissionGroupsList' },
    staticPermissionsList: { url: '/admin/permissionsList' },

    //Analaytics
    usersAnalaytics: { url: '/analaytics/users' },

    //Notifications
    notificationsList: { url: '/notifications/list' },
    addEditNotification: { url: '/notifications', params: [':id'] },

    //Settings
    languages: { url: '/settings/languages' },
    ageCategories: { url: '/settings/age-categories' },
    oAuth: { url: '/settings/signin-options' },
    companies: { url: '/settings/companies' },
    siteSetting: { url: '/settings/site-seeting' },
    UISiteSetting: { url: '/settings/ui-setting' },

    //reports
    contentReports: { url: "/reports/contents" },
    contentReport: { url: "/reports/content", params: [':contentId'] },

    shortList: { url: "/shorts" },
    shortDetail: { url: "/shorts/detail", params: [':id'] },
    shortVideos: { url: "/shorts/videos" },
    shortComments: { url: "/shorts/comments" },

    photoList: { url: "/photos" },
    photoDetail: { url: "/photos/detail", params: [':id'] },
    photoComments: { url: "/photos/comments" },

    podcastList: { url: "/podcasts" },
    podcastFileList: { url: "/podcasts/file" },
    podcastDetail: { url: "/podcasts/detail", params: [':id'] },
    podcastComments: { url: "/podcasts/comments" },

    reelList: { url: "/reels" },
    reelDetail: { url: "/reels/detail", params: [':id'] },
    reelVideos: { url: "/reels/videos" },
    reelComments: { url: "/reels/comments" },
};

export const findUrl = code => {
    if (urls.hasOwnProperty(code)) return urls[code].url;
    else return '#';
};

export const createRouteUrl = code => {
    if (urls.hasOwnProperty(code)) {
        let url = urls[code];

        let params = '';
        if (url.hasOwnProperty('params')) {
            params = '/' + url.params.join('/');
        }

        return url.url + params;
    } else return '#';
};

export const createUrl = (code, params = []) => {
    if (urls.hasOwnProperty(code)) {
        let url = urls[code];
        let paramUrl = '';

        if (params.length > 0) {
            paramUrl = '/' + params.join('/');
        }
        return url.url + paramUrl;
    } else return '#';
};
